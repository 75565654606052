.picture-zoom {
    display: none;
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: $color-one;
    opacity: 1;
    z-index: 10000;
    text-align: center;
    top: 0;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    overflow: hidden;
    & .img-bloc{
        width: 100%;
        overflow: auto;
        height: 80vh;
        & img {       
            object-fit: contain;
        }
    }
    & .close-zoom {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 20px;
        background-color: rgba($color-two, .8);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        & p {
            font-family: $policesGlobalBold;
            color: $color-one;
            display: none;
            opacity: 1;
            text-transform: uppercase;
            font-weight: 600;
        }
        & i {
            color: $color-one;
        }
    }
}

.zoom-active {
    display: flex;
}

@media (min-width: $phoneScreenSize) {
    .picture-zoom {
        align-self: center;
        border: 2px solid $color-three;
        & .img-bloc{  
            width: 90%;   
            & img {       
                width: $desktopScreenSize;
            }
        }
        & .close-zoom {            
            width: auto;
            height: 30px;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;
            gap: 30px;
            border-radius: 20px;
            padding: 5px 15px;
            & i {
                font-size: 30px;
            }
            & p {
                font-size: 25px;
                display: block;
            }
        }
    }
    
}